<template>
  <div class="content-block" :class="{ '-grouped': isGrouped, 'dark': darkMode }" :disabled="!active">
    <div class="instructions" v-if="showLeftInstructions && !hideInstructions">
      <slot name="instructions"/>
    </div>

    <div class="content">
      <panel class="panel-content" v-if="$slots.default || $slots.content || loading">
        <div class="innerinstructions" v-if="$slots.instructions && showInnerInstructions && !hideInstructions">
          <slot name="instructions"/>
        </div>

        <div class="loadinggrid" v-if="loading">
          <UIGradientLoader/>
          <UIGradientLoader/>
          <UIGradientLoader class="twospan"/>
          <UIGradientLoader class="twospan"/>
        </div>

        <div v-show="!loading" :class="{ fadeInContent  }">
          <slot name="content" />
          <slot />
        </div>
        <slot name="footer" slot="footer" />
      </panel>

      <slot name="additional"></slot>

      <div v-if="required" class="required">
        <span class="asterisk">*</span>
        {{ $t('Required') }}
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import Panel from './Panel';
import IsMobileMixin from '../misc/IsMobileMixin';
import UIGradientLoader from './ui/UIGradientLoader';

@Component({
  mixins: [
    IsMobileMixin,
  ],
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hideInstructions: {
      type: Boolean,
      default: false,
    },
    darkMode: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    Panel,
    UIGradientLoader,
  },
  inject: {
    isGrouped: {
      from: 'contentBlockGroup',
      default: false,
    },
  },
  watch: {
    loading: {
      handler: 'onLoadingChanged',
      immediate: true,
      deep: true,
    },
  },
})
export default class ContentBlock extends Vue {
  fadeInContent = false;

  onLoadingChanged (oldVal, newVal) {
    if (!oldVal && newVal) this.fadeInContent = true;
  }

  get showLeftInstructions () {
    return !this.isGrouped || !this.isMobile;
  }

  get showInnerInstructions () {
    return this.isGrouped && this.isMobile;
  }
}
</script>

<style scoped lang="postcss">
@import "../styles";

/* Default styles just include h1 to look like h5 and p to look like body */

.content-block {
  position: relative;
  transition: color 0.3s ease;
  will-change: color;
  margin-bottom: var(--spacingLg);
  & > .instructions {
    padding-bottom: var(--spacingSm);
    min-width: 0;
  }
  @media (--tablet) {
    & > .instructions {
      padding: var(--spacingSm) 0;
    }
  }
  @media (--desktop) {
    display: flex;
    align-items: stretch;
    margin-bottom: var(--spacingMd);

    & > .instructions {
      flex: 0 0 35rem;
      margin-right: var(--spacingMd);
    }

    & > .content {
      flex: 1 1 auto;
    }
  }

  @media (--desktopXL) {
    & > .instructions {
      flex: 0 0 35rem;
      margin-right: var(--spacingMd);
    }
  }

  & .innerinstructions {
    margin-bottom: var(--spacingMd);
  }
  & .instructions,
  & .innerinstructions {
    /* margin-bottom: var(--spacingMd); */
    & >>> h5,
    & >>> h3 {
      @apply --f3;

      margin: var(--spacingXS) 0;
      @media (--tablet) {
        margin: var(--spacingSm) 0;
      }
    }

    & >>> p {
      @apply --f5;

      font-size: 1.4rem;
    }

    & .number {
      @apply --f6;
    }

    & >>> * {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  & .panel-content {
    position: relative;

    & .fadeInContent {
      animation-name: fadeIn;
      animation-duration: 1s;
      animation-fill-mode: both;
    }

    & .loadinggrid {
      display: grid;
      grid-gap: var(--spacingSm);

      & .ui-gradient-loader {
        box-shadow: none;

        &:last-child {
          display: none;
        }
      }

      @media (--tablet) {
        grid-template-columns: repeat(2, 1fr);

        & .twospan {
          display: block !important;
          grid-column: span 2;
        }
      }

    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
      background-color: rgba(0, 0, 0, 0.1);
      /* background-color: color-mod(var(--colorSlate) alpha(10%)); */
      width: 100%;
      height: 100%;
      border-radius: var(--radius);
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  &.-grouped {
    & .panel-content {
      height: 100%;
      display: flex;
      align-items: center;
      & >>> .content {
        flex: 1 1 auto;

        padding-top: var(--spacingMd);
      }
    }
  }

  /* &.top-join {
    top: calc(calc(var(--spacingMd) + var(--radius)) * -1);
    margin-bottom: 0;

    @media (--desktop) {
      top: calc(calc(var(--spacingXL) + var(--radius)) * -1);
    }

    & .instructions {
      display: none;

      & .number {
        display: none;
      }

      @media (--desktop) {
        display: block;
        padding-top: var(--spacingMd);
      }
    }

    & .panel-content {
      height: 100%;
      display: flex;
      align-items: center;
      border-radius: 0;

      & >>> .content {
        flex: 1 1 auto;

        padding-top: var(--spacingSm);
      }
    }
  } */

  &.disabled,
  &[disabled] {
    color: var(--colorManatee);
    pointer-events: none;

    & .panel-content {
      &::after {
        opacity: 1;
      }
    }
  }

  & .required {
    @apply --f6;
    margin-top: var(--spacingXS);
    & .asterisk {
      color: var(--colorSBRedFlat);
    }
  }
}

.content-block.dark .panel-content {
  background-color: var(--darkModeColorDarkBackground) !important;
  color: var(--darkModeColorText) !important;
}
</style>
